import React from "react";
import { useSelector } from "react-redux";
import { Space, Typography, Button, Table, Tag, notification, Tabs, Divider, Checkbox } from "antd";
import { useIntl } from "react-intl";
import {
    RightOutlined,
    LeftOutlined,
    CopyOutlined
} from "@ant-design/icons";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styles from "./index.module.scss";

import { Link } from "react-router-dom";
import { RouterEnum } from "../../../enums/RouterEnum";
import { getPassiveAuthImgByLanguageAndTheme } from "../../../enums/ImageByLanguageEnum";

export default function VoidActiveAuthentication(props) {
    const theme = useSelector((state) => state.userSettings.theme);
    const language = useSelector((state) => state.language.language);
    const intl = useIntl();
    const epUrl = "https://active.besteron.com";
    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);

        notification.success({
            placement: "bottomRight",
            message: intl.formatMessage({ id: "global.presentation.copied" }),
        });
    };

    const columns = [
        {
            title: intl.formatMessage({ id: "global.presentation.name" }),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.type" }),
            dataIndex: 'type',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.required" }),
            dataIndex: 'required',
            render: (required, record) => {
                return <Checkbox checked={required} disabled />;
            },
        },
        {
            title: intl.formatMessage({ id: "global.presentation.example" }),
            dataIndex: 'example',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.note" }),
            dataIndex: 'note',
        },
    ];

    const requestHeader = [
        {
            name: 'Accept',
            type: 'string',
            required: true,
            example: 'application/json',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} application/json`,
        },
        {
            name: 'Content-type',
            type: 'string',
            required: true,
            example: 'application/x-www-form-urlencoded',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} application/x-www-form-urlencoded`,
        }
    ]

    const requestBody = [
        {
            name: 'grant_type',
            type: 'string',
            required: true,
            example: 'client_credentials',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} client_credentials`,
        },
        {
            name: 'client_id',
            type: 'string',
            required: true,
            example: '1234567890',
            note: 'CID',
        },
        {
            name: 'client_secret',
            type: 'string',
            required: true,
            example: 'abcdef1234567890',
            note: 'API Key',
        }
    ]

    const responseBody = [
        {
            name: 'token_type',
            type: 'string',
            required: true,
            example: 'bearer',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} bearer`,
        },
        {
            name: 'access_token',
            type: 'string',
            required: true,
            example: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQmVzdGVyb24ifQ.Bnz2ha8ctC-NXe0x16_lRCbArwk3DI0mCJwCxIRGcDw',
            note: '-',
        },
        {
            name: 'expires_in',
            type: 'int',
            required: true,
            example: '600',
            note: '-',
        },
        {
            name: 'grant_type',
            type: 'string',
            required: true,
            example: 'client_credentials',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} client_credentials`,
        }
    ]

    return (
        <div className={[
            styles.main,
            theme === "dark" ? styles["dark-mode"] : "",
        ].join(" ")}>
            <div className={styles.section}>
                <Space direction="vertical">
                    <div>
                        <Typography.Title level={2}>
                            {intl.formatMessage({ id: "global.presentation.authentication" })}
                        </Typography.Title>
                        <Typography.Text>
                            {intl.formatMessage({ id: "global.presentation.refundAuthentication.text1" })}
                        </Typography.Text>
                        <Typography.Link href="https://account.besteron.com/" target="_blank">
                            {intl.formatMessage({ id: "global.presentation.refundAuthentication.text2" })}
                        </Typography.Link>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <div className={styles.center}>
                    {getPassiveAuthImgByLanguageAndTheme(language, theme)?.img}
                </div>
            </div>
            <div className={styles.section}>
                <Space direction="vertical" className={styles["full-width"]}>
                    <Typography.Title level={5}>
                        {intl.formatMessage({ id: "global.presentation.refundAuthentication.getAccessToken" })}
                    </Typography.Title>
                    <Space direction="horizontal">
                        <Tag color="blue">POST</Tag>
                        <Typography.Text>
                            {epUrl}/api/oauth2/token
                        </Typography.Text>
                        <CopyOutlined onClick={() => copyToClipboard(`${epUrl}/api/oauth2/token`)} />
                    </Space>
                    <div className={styles["api-section"]}>
                        <div className={styles.left}>
                            {intl.formatMessage({ id: "global.presentation.refundAuthentication.text3" })}
                            <Divider orientation="left">
                                {intl.formatMessage({ id: "global.presentation.request" })}
                            </Divider>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={requestHeader}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.header" })}
                                    pagination={false}
                                    rowKey={"name"}
                                    scroll={{ x: 'max-content' }}
                                />
                            </div>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={requestBody}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.body" })}
                                    pagination={false}
                                    rowKey={"name"}
                                    scroll={{ x: 'max-content' }}
                                />
                            </div>
                            <Divider orientation="left">
                                {`${intl.formatMessage({ id: "global.presentation.response" })} (200 - OK)`}
                            </Divider>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={responseBody}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.body" })}
                                    pagination={false}
                                    rowKey={"name"}
                                    scroll={{ x: 'max-content' }}
                                />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.sticky}>
                            <Tabs>
                                <Tabs.TabPane tab="HTTP" key="HTTP">
                                    <SyntaxHighlighter language="http" style={theme == 'dark' ? a11yDark : a11yLight}>
                                        {`POST ${epUrl}/api/oauth2/token
Accept: application/json
Content-type: application/x-www-form-urlencoded
                                            
grant_type=client_credentials
&client_id=<CID>
&client_secret=<API KEY>`}
                                    </SyntaxHighlighter>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Response" key="Response">
                                    <SyntaxHighlighter language="json" style={theme == 'dark' ? a11yDark : a11yLight}>
                                        {`{
  "token_type": "bearer",
  "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQmVzdGVyb24ifQ.Bnz2ha8ctC-NXe0x16_lRCbArwk3DI0mCJwCxIRGcDw",
  "expires_in": 600,
  "grant_type": "client_credentials"
}`}
                                    </SyntaxHighlighter>
                                </Tabs.TabPane>
                            </Tabs>
                            </div>
                        </div>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <Space direction="horizontal">
                    <Link to={RouterEnum.CancelRecurringRequest.Path}>
                        <Button type="primary">
                            <LeftOutlined />
                            {intl.formatMessage({ id: "global.presentation.previous" })}
                        </Button>
                    </Link>
                    <Link to={RouterEnum.VoidCapture.Path}>
                        <Button type="primary">
                            {intl.formatMessage({ id: "global.presentation.next" })}
                            <RightOutlined />
                        </Button>
                    </Link>
                </Space>
            </div>
        </div>
    );
}