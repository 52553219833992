import React from "react";
import { useSelector } from "react-redux";
import { Space, Typography, Button, Table } from "antd";
import { useIntl } from "react-intl";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import styles from "./index.module.scss";

import { Link } from "react-router-dom";
import { RouterEnum } from "../../../enums/RouterEnum";

export default function VoidCompletionType(props) {
  const theme = useSelector((state) => state.userSettings.theme);
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: "global.presentation.value" }),
      dataIndex: "value",
      width: "50%",
    },
    {
      title: intl.formatMessage({ id: "global.presentation.description" }),
      dataIndex: "description",
      width: "50%",
    },
  ];

  const data = [
    {
      value: "CAPTURE",
      description: intl.formatMessage({
        id: "global.presentation.void.completionType.capture",
      }),
    },
    {
      value: "CANCEL",
      description: intl.formatMessage({
        id: "global.presentation.void.completionType.cancel",
      }),
    }
  ];

  return (
    <div
      className={[
        styles.main,
        theme === "dark" ? styles["dark-mode"] : "",
      ].join(" ")}
    >
      <div className={styles.section}>
        <Space direction="vertical">
          <div>
            <Typography.Title level={2}>Void completion type</Typography.Title>
          </div>
        </Space>
      </div>
      <div className={styles.section}>
        <Space direction="vertical" className={styles["full-width"]}>
          <div className={styles["api-section"]}>
            <div className={styles.object}>
              <div className={styles.table}>
                <Table
                  columns={columns}
                  dataSource={data}
                  bordered
                  pagination={false}
                  rowKey={"name"}
                  scroll={{ x: 'max-content' }}
                />
              </div>
            </div>
          </div>
        </Space>
      </div>
      <div className={styles.section}>
        <Space direction="horizontal">
          <Link to={RouterEnum.RecurringStatus.Path}>
            <Button type="primary">
              <LeftOutlined />
              {intl.formatMessage({ id: "global.presentation.previous" })}
            </Button>
          </Link>
          <Link to={RouterEnum.OnDemandStatus.Path}>
            <Button type="primary">
              {intl.formatMessage({ id: "global.presentation.next" })}
              <RightOutlined />
            </Button>
          </Link>
        </Space>
      </div>
    </div>
  );
}
