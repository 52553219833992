import { createReducer } from "redux-act";

import * as action from "../actions/userSettingsAction";

const initialState = {
  theme: "light",
};

const setInitialState = () => {
  let localStorageTheme = localStorage.getItem("theme");

  if (localStorageTheme === "light" || localStorageTheme === "dark")
    initialState.theme = localStorageTheme;

  return initialState;
};

const userSettingsReducer = createReducer(
  {
    [action.changeTheme]: (state, payload) => {
      return {
        ...state,
        theme: payload,
      };
    },
  },
  setInitialState()
);

export default userSettingsReducer;
