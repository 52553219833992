import React from "react";
import { useSelector } from "react-redux";
import { Space, Typography, Button, Table, Tag, notification, Tabs, Divider, Checkbox } from "antd";
import { useIntl } from "react-intl";
import {
    RightOutlined,
    LeftOutlined,
    CopyOutlined
} from "@ant-design/icons";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styles from "./index.module.scss";

import { Link, Route } from "react-router-dom";
import { RouterEnum } from "../../../enums/RouterEnum";
import { getIntegrionInfoByLanguage } from "../../../enums/ImageByLanguageEnum";

export default function OnDemandTransaction(props) {
    const theme = useSelector((state) => state.userSettings.theme);
    const intl = useIntl();

    const columns = [
        {
            title: intl.formatMessage({ id: "global.presentation.name" }),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.type" }),
            dataIndex: 'type',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.required" }),
            dataIndex: 'required',
            render: (required, record) => {
                return <Checkbox checked={required} disabled />;
            },
        },
        {
            title: intl.formatMessage({ id: "global.presentation.restriction" }),
            dataIndex: 'restriction',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.example" }),
            dataIndex: 'example',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.note" }),
            dataIndex: 'note',
        },
    ];

    const requestBody = [
        {
            name: 'transactionId',
            type: 'string',
            required: true,
            restriction: `-`,
            example: '5s3ec6fe-2f15-47b8-88aa-4a08b2egb8dd',
            note: `${intl.formatMessage({ id: "global.presentation.transactionId" })}`,
        },
        {
            name: 'createdAt',
            type: 'string',
            required: true,
            restriction: `-`,
            example: '2022-01-01T05:15:55.646792Z',
            note: `${intl.formatMessage({ id: "global.presentation.createdAt" })}`,
        },
        {
            name: 'updatedAt',
            type: 'string',
            required: true,
            restriction: `-`,
            example: '2022-01-01T05:15:55.646792Z',
            note: `${intl.formatMessage({ id: "global.presentation.updatedAt" })}`,
        },
        {
            name: 'status',
            type: 'string',
            required: true,
            restriction: `-`,
            example: 'Created',
            note: <>
                {intl.formatMessage({ id: "global.presentation.status" })}{". "}
                {intl.formatMessage({ id: "global.presentation.enumeration" })}{": "}
                <Typography.Link href={RouterEnum.OnDemandStatus.Path} target="_blank">
                    Status
                </Typography.Link>
            </>
        },
        {
            name: 'onDemandTransactions',
            type: 'List<string>',
            required: true,
            restriction: `-`,
            example: '["c3b406aa-9812-4992-9e6b-63dfd97232f0", "c2b406aa-9412-4992-9e6b-63dfd97232f0"]',
            note: <>
                {intl.formatMessage({ id: "global.presentation.onDemandTransaction.onDemandTransactions" })}{": "}
                <Typography.Link href={RouterEnum.PassiveInformation.Path} target="_blank">
                    {intl.formatMessage({
                        id: "global.presentation.obtainingInformation",
                    })}
                </Typography.Link>
            </>
        }
    ]

    return (
        <div className={[
            styles.main,
            theme === "dark" ? styles["dark-mode"] : "",
        ].join(" ")}>
            <div className={styles.section}>
                <Space direction="vertical">
                    <div>
                        <Typography.Title level={2}>
                            OnDemandTransaction
                        </Typography.Title>
                        <Typography.Text>
                            {intl.formatMessage({ id: "global.presentation.onDemandTransaction.text1" })}
                        </Typography.Text>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <Space direction="vertical" className={styles["full-width"]}>
                    <div className={styles["api-section"]}>
                        <div className={styles.object}>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={requestBody}
                                    bordered
                                    pagination={false}
                                    rowKey={"name"}
                                    scroll={{ x: 'max-content' }}
                                />
                            </div>
                        </div>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <Space direction="horizontal">
                    <Link to={RouterEnum.Void.Path}>
                        <Button type="primary">
                            <LeftOutlined />
                            {intl.formatMessage({ id: "global.presentation.previous" })}
                        </Button>
                    </Link>
                    <Link to={RouterEnum.RefundRequestCallback.Path}>
                        <Button type="primary">
                            {intl.formatMessage({ id: "global.presentation.next" })}
                            <RightOutlined />
                        </Button>
                    </Link>
                </Space>
            </div>
        </div>
    );
}