import React from "react";
import { useSelector } from "react-redux";
import { Space, Typography, Button, Table, Tag, notification, Tabs, Divider, Checkbox } from "antd";
import { useIntl } from "react-intl";
import {
    RightOutlined,
    LeftOutlined,
    CopyOutlined
} from "@ant-design/icons";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styles from "./index.module.scss";

import { Link, Route } from "react-router-dom";
import { RouterEnum } from "../../../enums/RouterEnum";
import { getIntegrionInfoByLanguage } from "../../../enums/ImageByLanguageEnum";

export default function OnDemandPaymentIntentCreation(props) {
    const theme = useSelector((state) => state.userSettings.theme);
    const language = useSelector((state) => state.language.language);
    const intl = useIntl();
    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);

        notification.success({
            placement: "bottomRight",
            message: intl.formatMessage({ id: "global.presentation.copied" }),
        });
    };

    const columns = [
        {
            title: intl.formatMessage({ id: "global.presentation.name" }),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.type" }),
            dataIndex: 'type',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.required" }),
            dataIndex: 'required',
            render: (required, record) => {
                return <Checkbox checked={required} disabled />;
            },
        },
        {
            title: intl.formatMessage({ id: "global.presentation.example" }),
            dataIndex: 'example',
        },
        {
            title: intl.formatMessage({ id: "global.presentation.note" }),
            dataIndex: 'note',
        },
    ];

    const requestHeader = [
        {
            name: 'Accept',
            type: 'string',
            required: true,
            example: 'application/json',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} application/json`,
        },
        {
            name: 'Content-type',
            type: 'string',
            required: true,
            example: 'application/json',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} application/json`,
        },
        {
            name: 'Authorization',
            type: 'string',
            required: true,
            example: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQmVzdGVyb24ifQ.Bnz2ha8ctC-NXe0x16_lRCbArwk3DI0mCJwCxIRGcDw',
            note: `${intl.formatMessage({ id: "global.presentation.only" })} Bearer <Access token>`,
        }
    ]

    const requestBody = [
        {
            name: 'totalAmount',
            type: 'int',
            required: true,
            example: '100',
            note: `${intl.formatMessage({ id: "global.presentation.paymentIntentCreation.totalAmount" })}`,

        },
        {
            name: 'currencyCode',
            type: 'string',
            required: true,
            example: 'EUR',
            note: `${intl.formatMessage({ id: "global.presentation.paymentIntentCreation.currencyCode" })}`,
        },
        {
            name: 'orderNumber',
            type: 'string',
            required: true,
            example: 'OBJ12345',
            note: `${intl.formatMessage({ id: "global.presentation.paymentIntentCreation.orderNumber" })}`,
        },
        {
            name: 'transactionType',
            type: 'string',
            required: true,
            example: 'ONDEMAND',
            note: `${intl.formatMessage({ id: "global.presentation.paymentIntentCreation.transactionType" })}`,
        },
        {
            name: <Typography.Link href={RouterEnum.Language.Path} target="_blank">
                language
            </Typography.Link>,
            type: 'string',
            required: true,
            example: 'SK',
            note: `${intl.formatMessage({ id: "global.presentation.paymentIntentCreation.language" })}`,
        },
        {
            name: 'paymentMethods',
            type: 'List<string>',
            required: true,
            example: '["GIBASKBX", "POBNSKBA"]',
            note: `${intl.formatMessage({ id: "global.presentation.paymentIntentCreation.paymentMethods" })}`,
        },
        {
            name: 'items',
            type: <>
                {'List<'}
                <Typography.Link href={RouterEnum.Item.Path} target="_blank">
                    item
                </Typography.Link>
                {'>'}
            </>,
            required: true,
            example: '-',
            note: `${intl.formatMessage({ id: "global.presentation.paymentIntentCreation.items" })}`,
        },
        {
            name: 'callback',
            type: <>
                <Typography.Link href={RouterEnum.Callback.Path} target="_blank">
                    callback
                </Typography.Link>
            </>,
            required: true,
            example: '-',
            note: `${intl.formatMessage({ id: "global.presentation.paymentIntentCreation.callback" })}`,
        },
        {
            name: 'buyer',
            type: <>
                <Typography.Link href={RouterEnum.Buyer.Path} target="_blank">
                    buyer
                </Typography.Link>
            </>,
            required: true,
            example: '-',
            note: `${intl.formatMessage({ id: "global.presentation.paymentIntentCreation.buyer" })}`,
        }
    ]

    const responseBody = [
        {
            name: 'redirectUrl',
            type: 'string',
            required: true,
            example: 'https://gate.besteron.com/widget?sh=PujHTfdjbh5jedh7jkeE',
            note: `-`,
        },
        {
            name: 'transactionId',
            type: 'guid',
            required: true,
            example: 'c027395e-71cb-464b-8af3-11838787e9fb',
            note: `-`,
        },
        {
            name: 'onDemandTransactionId',
            type: 'guid',
            required: false,
            example: 'd087345g-73cx-414b-8hf3-94638787e9qw',
            note: `-`,
        }
    ]

    return (
        <div className={[
            styles.main,
            theme === "dark" ? styles["dark-mode"] : "",
        ].join(" ")}>
            <div className={styles.section}>
                <Space direction="vertical">
                    <div>
                        <Typography.Title level={2}>
                            {intl.formatMessage({ id: "global.presentation.onDemandPaymentCreation" })}
                        </Typography.Title>
                        <Typography.Text>
                            {intl.formatMessage({ id: "global.presentation.onDemandPaymentCreation.text1" })}
                        </Typography.Text>
                        <Typography.Link href={RouterEnum.Authentication.Path} target="_blank">
                            {intl.formatMessage({ id: "global.presentation.authentication" })}
                        </Typography.Link>
                        <Typography.Text>
                            {intl.formatMessage({ id: "global.presentation.onDemandPaymentCreation.chapter" })}
                        </Typography.Text>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <Space direction="vertical" className={styles["full-width"]}>
                    <Typography.Title level={5}>
                        {intl.formatMessage({ id: "global.presentation.onDemandPaymentCreation" })}
                    </Typography.Title>
                    <Space direction="horizontal">
                        <Tag color="blue">POST</Tag>
                        <Typography.Text>
                            https://gate.besteron.com/api/payment-intent
                        </Typography.Text>
                        <CopyOutlined onClick={() => copyToClipboard("https://gate.besteron.com/api/payment-intent")} />
                    </Space>
                    <div className={styles["api-section"]}>
                        <div className={styles.left}>
                            {intl.formatMessage({ id: "global.presentation.onDemandPaymentCreation.text2" })}
                            <Typography.Link href={RouterEnum.PaymentIntentCreation.Path} target="_blank">
                                {intl.formatMessage({ id: "global.presentation.paymentCreation" })}
                            </Typography.Link>
                            {". "}
                            {intl.formatMessage({ id: "global.presentation.onDemandPaymentCreation.text3" })}
                            <Typography.Paragraph>
                                <ul>
                                    <li>
                                        {intl.formatMessage({ id: "global.presentation.onDemandPaymentCreation.text4" })}
                                    </li>
                                    <li>
                                        {intl.formatMessage({ id: "global.presentation.onDemandPaymentCreation.text5" })}
                                    </li>
                                    <li>
                                        {intl.formatMessage({ id: "global.presentation.onDemandPaymentCreation.text6" })}
                                    </li>
                                </ul>
                            </Typography.Paragraph>
                            <Divider orientation="left">
                                {intl.formatMessage({ id: "global.presentation.request" })}
                            </Divider>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={requestHeader}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.header" })}
                                    pagination={false}
                                    rowKey={"name"}
                                    scroll={{ x: 'max-content' }}
                                />
                            </div>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={requestBody}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.body" })}
                                    pagination={false}
                                    rowKey={"name"}
                                    scroll={{ x: 'max-content' }}
                                />
                            </div>
                            <Divider orientation="left">
                                {`${intl.formatMessage({ id: "global.presentation.response" })} (200 - OK)`}
                            </Divider>
                            <div className={styles.table}>
                                <Table
                                    columns={columns}
                                    dataSource={responseBody}
                                    bordered
                                    title={() => intl.formatMessage({ id: "global.presentation.body" })}
                                    pagination={false}
                                    rowKey={"name"}
                                    scroll={{ x: 'max-content' }}
                                />
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.sticky}>
                                <Tabs>
                                    <Tabs.TabPane tab="HTTP" key="HTTP">
                                        <SyntaxHighlighter language="http" style={theme == 'dark' ? a11yDark : a11yLight}>
                                            {`POST https://gate.besteron.com/api/payment-intent
Accept: application/json
Content-type: application/json
Authorization: Bearer <Access Token>

{
    "totalAmount": 20,
    "currencyCode": "EUR",
    "orderNumber": "OBJ01234",
    "transactionType": "ONDEMAND",
    "language": "SK",
    "paymentMethods": [
        "CARD"
    ],
    "buyer": {
        "email": "michael@casey.com",
    },
    "items": [
        {
            "name": "teacup",
            "type": "ITEM",
            "amount": 20,
            "count": 2,
            "vatRate": 20,
            "ean": "ITEM0981234",
            "productUrl": "https://example.com/teacup"
        },
    ],
    "callback": {
        "returnUrl": "https://example.com/return",
        "notificationUrl": "https://example.com/notification",
        "recurringUrl": "https://example.com/recurring"
    }
}`}
                                        </SyntaxHighlighter>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="Response" key="Response">
                                        <SyntaxHighlighter language="json" style={theme == 'dark' ? a11yDark : a11yLight}>
                                            {`{
  "redirectUrl": "https://gate.besteron.com/widget?sh=PujHTfdjbh5jedh7jkeE",
  "transactionId": "c027395e-71cb-464b-8af3-11838787e9fb",
  "onDemandTransactionId": "d087345g-73cx-414b-8hf3-94638787e9qw"
}`}
                                        </SyntaxHighlighter>
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </Space>
            </div>
            <div className={styles.section}>
                <Space direction="horizontal">
                    <Link to={RouterEnum.VoidPaymentIntentCreation.Path}>
                        <Button type="primary">
                            <LeftOutlined />
                            {intl.formatMessage({ id: "global.presentation.previous" })}
                        </Button>
                    </Link>
                    <Link to={RouterEnum.DisplayRedirect.Path}>
                        <Button type="primary">
                            {intl.formatMessage({ id: "global.presentation.next" })}
                            <RightOutlined />
                        </Button>
                    </Link>
                </Space>
            </div>
        </div>
    );
}