import React from "react";
import { useSelector } from "react-redux";
import { Space, Typography, Button, Table, Tag, notification, Tabs, Divider, Checkbox } from "antd";
import { useIntl } from "react-intl";
import {
  RightOutlined,
  LeftOutlined,
  CopyOutlined
} from "@ant-design/icons";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight, a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import styles from "./index.module.scss";

import { Link, Route } from "react-router-dom";
import { RouterEnum } from "../../../enums/RouterEnum";
import { getIntegrionInfoByLanguage } from "../../../enums/ImageByLanguageEnum";

export default function VoidCapture(props) {
  const theme = useSelector((state) => state.userSettings.theme);
  const language = useSelector((state) => state.language.language);
  const epUrl = "https://active.besteron.com";
  const intl = useIntl();
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);

    notification.success({
      placement: "bottomRight",
      message: intl.formatMessage({ id: "global.presentation.copied" }),
    });
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "global.presentation.name" }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: "global.presentation.type" }),
      dataIndex: 'type',
    },
    {
      title: intl.formatMessage({ id: "global.presentation.required" }),
      dataIndex: 'required',
      render: (required, record) => {
        return <Checkbox checked={required} disabled />;
      },
    },
    {
      title: intl.formatMessage({ id: "global.presentation.restriction" }),
      dataIndex: 'restriction',
      className: styles['col-restriction']
    },
    {
      title: intl.formatMessage({ id: "global.presentation.example" }),
      dataIndex: 'example',
    },
    {
      title: intl.formatMessage({ id: "global.presentation.note" }),
      dataIndex: 'note',
    },
  ];

  const responseColumns = [
    {
      title: intl.formatMessage({ id: "global.presentation.name" }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: "global.presentation.type" }),
      dataIndex: 'type',
    },
    {
      title: intl.formatMessage({ id: "global.presentation.required" }),
      dataIndex: 'required',
      render: (required, record) => {
        return <Checkbox checked={required} disabled />;
      },
    },
    {
      title: intl.formatMessage({ id: "global.presentation.note" }),
      dataIndex: 'note',
    },
  ];

  const requestHeader = [
    {
      name: 'Accept',
      type: 'string',
      required: true,
      example: 'application/json',
      note: `${intl.formatMessage({ id: "global.presentation.only" })} application/json`,
    },
    {
      name: 'Content-type',
      type: 'string',
      required: true,
      example: 'application/json',
      note: `${intl.formatMessage({ id: "global.presentation.only" })} application/json`,
    },
    {
      name: 'Authorization',
      type: 'string',
      required: true,
      example: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQmVzdGVyb24ifQ.Bnz2ha8ctC-NXe0x16_lRCbArwk3DI0mCJwCxIRGcDw',
      note: `${intl.formatMessage({ id: "global.presentation.only" })} Bearer <Access token>`,
    }
  ]

  const requestBody = []

  const responseBody = []

  return (
    <div className={[
      styles.main,
      theme === "dark" ? styles["dark-mode"] : "",
    ].join(" ")}>
      <div className={styles.section}>
        <Space direction="vertical">
          <div>
            <Typography.Title level={2}>
              {intl.formatMessage({ id: "global.presentation.void.completionType.capture" })}
            </Typography.Title>
            <Typography.Text>
              {intl.formatMessage({ id: "global.presentation.void.completionType.capture.text1" })}
            </Typography.Text>
          </div>
        </Space>
      </div>
      <div className={styles.section}>
        <Space direction="vertical" className={styles["full-width"]}>
          <Typography.Title level={5}>
            {intl.formatMessage({ id: "global.presentation.void.completionType.capture" })}
          </Typography.Title>
          <Space direction="horizontal">
            <Tag color="blue">POST</Tag>
            <Typography.Text>
              {epUrl}{"/api/payment-intents/void/capture/{transactionId}"}
            </Typography.Text>
            <CopyOutlined onClick={() => copyToClipboard(`${epUrl}/api/payment-intents/void/capture/{transactionId}`)} />
          </Space>
          <div className={styles["api-section"]}>
            <div className={styles.left}>
              {intl.formatMessage({ id: "global.presentation.void.completionType.capture.text2" })}
              <Typography.Link href={RouterEnum.VoidAuthentication.Path} target="_blank">
                {intl.formatMessage({ id: "global.presentation.authentication" })}
              </Typography.Link>
              {" "}
              <Divider orientation="left">
                {intl.formatMessage({ id: "global.presentation.request" })}
              </Divider>
              <div className={styles.table}>
                <Table
                  columns={columns}
                  dataSource={requestHeader}
                  bordered
                  title={() => intl.formatMessage({ id: "global.presentation.header" })}
                  pagination={false}
                  rowKey={"name"}
                  scroll={{ x: 'max-content' }}
                />
              </div>
              <div className={styles.table}>
                <Table
                  columns={columns}
                  dataSource={requestBody}
                  bordered
                  title={() => intl.formatMessage({ id: "global.presentation.body" })}
                  pagination={false}
                  rowKey={"name"}
                  scroll={{ x: 'max-content' }}
                />
              </div>
              <Divider orientation="left">
                {`${intl.formatMessage({ id: "global.presentation.response" })} (200 - OK)`}
              </Divider>
              <div className={styles.table}>
                <Table
                  columns={responseColumns}
                  dataSource={responseBody}
                  bordered
                  title={() => intl.formatMessage({ id: "global.presentation.body" })}
                  pagination={false}
                  rowKey={"name"}
                  scroll={{ x: 'max-content' }}
                />
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.sticky}>
                <Tabs>
                  <Tabs.TabPane tab="HTTP" key="HTTP">
                    <SyntaxHighlighter language="http" style={theme == 'dark' ? a11yDark : a11yLight}>
                      {`POST ${epUrl}/api/payment-intents/void/capture/5s3ec6fe-2f15-47b8-88aa-4a08b2egb8dd
Accept: application/json
Content-type: application/json
Authorization: Bearer <Access Token>`}
                    </SyntaxHighlighter>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Response" key="Response">
                    <SyntaxHighlighter language="json" style={theme == 'dark' ? a11yDark : a11yLight}>
                      {}
                    </SyntaxHighlighter>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </Space>
      </div>
      <div className={styles.section}>
        <Space direction="horizontal">
          <Link to={RouterEnum.VoidAuthentication.Path}>
            <Button type="primary">
              <LeftOutlined />
              {intl.formatMessage({ id: "global.presentation.previous" })}
            </Button>
          </Link>
          <Link to={RouterEnum.VoidCancel.Path}>
            <Button type="primary">
              {intl.formatMessage({ id: "global.presentation.next" })}
              <RightOutlined />
            </Button>
          </Link>
        </Space>
      </div>
    </div>
  );
}